import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import useLocales from 'hooks/useLocales';
import { styled } from '@mui/material';


type setFieldValueFn = (field: string, value: any, shouldValidate?: boolean | undefined) => void;

type Props = {
    keyText?: string | number
    lineText: string | number
    id?: string | number
    setFieldValue?: setFieldValueFn;
    handleHighlight: (arg: string | number) => void
    field?: string;
    fieldValue?: string | number
}

export default function MagicWand({ keyText, lineText, id, handleHighlight, fieldValue, setFieldValue, field }: Props) {
    const { translate } = useLocales();

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const idPop = open ? 'simple-popover' : undefined;

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const setField = (txt: string | number) => {
        setFieldValue && field && setFieldValue(field, txt);
        handleClose()
    };

    return (
        <div>
            <IconButton onClick={handleClick} sx={{ color: '#2065D1' }}>
                <AutoFixHighIcon />
            </IconButton>
            <Popover
                id={idPop}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Typography
                    sx={{
                        color: '#637381',
                        textTransform: 'uppercase',
                        fontWeight: 700,
                        padding: '1rem 1rem',
                    }}
                >
                    {translate('suggestions.fromSuggestions', 'From suggestion')}
                </Typography>

                <RootDiv onMouseOver={() => handleHighlight(lineText)} onClick={() => fieldValue && setField(fieldValue)}>
                    <p>
                        <span>{keyText}</span> &nbsp;
                        {lineText}
                    </p>
                </RootDiv>
            </Popover>
        </div>
    );
}

const RootDiv = styled('div')(({ theme }) => ({
    background: 'rgba(145, 158, 171, 0.16)',
    padding: '1rem',
    maxWidth: '31rem',
    marginBottom: '2px',
    color: '#637381',
    textAlign: 'justify',
    cursor: 'pointer',
    'span': {
        fontWeight: 'bold'
    }
}))