import { Alert, AlertTitle, Box, Grid, Typography } from '@mui/material'
import InputControlSelect from 'components/reusable/input-controls/InputControlSelect'
import { getExtendTerminateOptions, IContractFormValues, IExtendTerminateOptions } from '../../../../../constants/initialValues/contractFormInitialValues'
import { FormikProps } from 'formik'
import useLocales from 'hooks/useLocales'
import React, { useMemo, useState } from 'react'
import { bluePreset } from 'utils/getColorPresets'
import { LoadingButton } from '@mui/lab'
import FMDatePicker from 'components/reusable/formik-inputs/FMDatePicker'
import { useEditContractProvider } from 'contexts/EditContractContext'
import { useTerminateContractMutation } from 'redux/api/contractApi'
import { useParams } from 'react-router'
import { IContractStatus } from '../../../../../@types/contractResponse'
import moment from 'moment'
import useAPIError from 'hooks/useAPIError'
import { useSnackbar } from 'notistack'

type Props = {
    formik: FormikProps<IContractFormValues>
    selectedAction: IExtendTerminateOptions | undefined
    setSelectedAction: (arg: IExtendTerminateOptions) => void
}

const ExtendTerminateOption = ({ formik, selectedAction, setSelectedAction }: Props) => {
    const { id: contractId } = useParams()
    const { handleError } = useAPIError()
    const { enqueueSnackbar } = useSnackbar()
    const { values } = formik
    const { translate } = useLocales()
    const { contract } = useEditContractProvider()
    const [terminateContract, { isLoading: isLoadingTermination, isSuccess }] = useTerminateContractMutation();

    const [selectActionBorderColor, setSelectActionBorderColor] = useState(bluePreset.light);
    const contractTerminated = contract?.contractStatus === IContractStatus.TERMINATED

    useMemo(() => {
        if (isSuccess) {
            let msg = translate('contract.terminated', 'Contract terminated!')
            if (contractTerminated) {
                msg = translate('contract.terminationUndo', 'Contract termination undo!')
            }
            enqueueSnackbar(msg, { variant: "success" })
        }
    }, [contractTerminated, enqueueSnackbar, isSuccess, translate])

    const handleTerminate = async () => {
        try {
            await terminateContract({ contractId: contractId! })

        } catch (error) {
            handleError(error)
        }
    }

    const handleChange = (e: any) => setSelectedAction(e.target.value)

    const dropdownOptions = getExtendTerminateOptions(translate)

    let orgStartDate = contract?.contractPeriods?.[0]?.startDate ? contract?.contractPeriods?.[0]?.startDate : contract?.aiSuggestionResponse?.effectiveDates?.[0].date
    let orgEndDate = contract?.contractPeriods?.[0]?.endDate ? contract?.contractPeriods?.[0]?.endDate : contract?.aiSuggestionResponse?.endDates?.[0].date

    return (
        <Box sx={{
            backgroundColor: 'transparent',
            borderColor: selectActionBorderColor,
            borderStyle: "solid",
            borderWidth: "1px",
            borderRadius: ".5rem",
            padding: "1rem",
            marginBottom: 3
        }}>
            <Alert variant="outlined" severity="info" sx={{ borderWidth: 0, backgroundColor: 'transparent', ml: 0, pl: 0 }} >
                <AlertTitle>
                    {translate('warnings.contractEndDateHasPassed', 'The contract end date has passed - action is required')}
                </AlertTitle>
            </Alert>
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    <InputControlSelect name='actionOption' label={translate('contractQuickAction.selectAction', 'Select action')} onChange={handleChange} value={selectedAction as string} options={dropdownOptions} />
                </Grid>
                {selectedAction && selectedAction === IExtendTerminateOptions.TERMINATE_CONTRACT && <Grid item sm={12}>
                    <LoadingButton loading={isLoadingTermination} variant='contained' onClick={handleTerminate} >
                        {
                            contractTerminated ?
                                translate('contract.quickAction.UndoTermination', 'Undo Termination') :
                                translate('contract.quickAction.terminate', 'Terminate')
                        }
                    </LoadingButton>
                </Grid>}
                {selectedAction && selectedAction === IExtendTerminateOptions.EXTEND_CONTRACT && <Grid item container spacing={2} sm={12}>
                    <Grid item sm={6}>
                        <FMDatePicker name='newPeriodStartDate' label={translate('contract.newPeriodStartDate', 'New period start date')} />
                    </Grid>
                    <Grid item sm={6}>
                        <FMDatePicker name='newPeriodEndDate' label={translate('contract.newPeriodEndDate', 'New period end date')} />
                    </Grid>
                    <Grid item sm={12}>
                        <Box sx={{ display: 'flex' }}>
                            <Typography sx={{ mr: ".3rem" }}>
                                {
                                    contract?.contractStatus === "DRAFT" ?
                                        translate("contractDate.originalContractPeriod", "Original contract period") :
                                        translate("contractDate.previousContractPeriod", "Previous contract period")
                                } :</Typography>
                            <Typography sx={{ mr: ".3rem" }}> {orgStartDate} </Typography>
                            {orgEndDate && <Typography sx={{ mr: ".3rem" }}> {translate("contractDate.to", "to")} {orgEndDate}</Typography>}
                        </Box>
                    </Grid>
                </Grid>}

            </Grid>
        </Box>
    )
}

export default ExtendTerminateOption