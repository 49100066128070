import React, { createContext, useState, ReactNode, FC, useContext, Dispatch, SetStateAction } from 'react';

type AreaItem = {
  pageIndex: number;
  height: number;
  width: number;
  left: number;
  top: number;
};

type IContractContext = {
  handleHighlight: (str: string) => void
  urlLink: string | null
  setUrlLink: Dispatch<SetStateAction<string>>
  areas: AreaItem[],
  setAreas: Dispatch<SetStateAction<AreaItem[]>>
  highlight: string | null
}
// @ts-ignore
export const ContractContext = createContext<IContractContext>({
  handleHighlight: (str) => { },
  urlLink: null,
  setUrlLink: (str) => { },
  areas: [],
  setAreas: (str) => { },
  highlight: "",
});

type Props = { children: ReactNode };

export function useContract() {
  return useContext(ContractContext);
}



export const ContractProvider: FC<Props> = ({ children }) => {
  const [urlLink, setUrlLink] = useState<string>('');
  const [areas, setAreas] = useState<AreaItem[]>([]);
  const [highlight, setHighlight] = useState<string>(" ");

  const handleHighlight = (text: string | null) => {
    if (text) {
      setHighlight(text);
    }
  }

  return (
    <ContractContext.Provider value={{ urlLink, setUrlLink, areas, setAreas, highlight, handleHighlight }}>{children}</ContractContext.Provider>
  );
};
