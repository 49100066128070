import { useMemo, ReactNode } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeOptions,
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
// hooks
import useSettings from '../hooks/useSettings';
import useAuth from 'hooks/useAuth';
//
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

export default function ThemeProvider({ children }: Props) {
  const { themeMode, themeDirection } = useSettings();
  const auth = useAuth();
  const isLight = themeMode === 'light';
  console.log("themeMode", themeMode);
  console.log("themeMode auth.theme?.name", auth.theme?.name);

  if (auth.theme?.name) {
    //@ts-ignore
    typography.fontFamily = palette[auth.theme?.name]?.fontFamily ?? typography.fontFamily;
    //@ts-ignore
    typography.h1.fontFamily = palette[auth.theme?.name]?.secondaryFontFamily ?? typography.h1.fontFamily;
    //@ts-ignore
    typography.h2.fontFamily = palette[auth.theme?.name]?.secondaryFontFamily ?? typography.h2.fontFamily;
    //@ts-ignore
    typography.h3.fontFamily = palette[auth.theme?.name]?.secondaryFontFamily ?? typography.h3.fontFamily;
    //@ts-ignore
    typography.h4.fontFamily = palette[auth.theme?.name]?.secondaryFontFamily ?? typography.h4.fontFamily;
    //@ts-ignore
    typography.h5.fontFamily = palette[auth.theme?.name]?.secondaryFontFamily ?? typography.h5.fontFamily;
    //@ts-ignore
    typography.h6.fontFamily = palette[auth.theme?.name]?.secondaryFontFamily ?? typography.h6.fontFamily;
    //@ts-ignore
    typography.button.fontWeight = palette[auth.theme?.name]?.btnFontWeight ?? typography.button.fontWeight;
  };

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: isLight ? palette.light : palette.dark,
      //@ts-ignore
      customPalette: auth.theme?.name ? palette[auth.theme?.name] : palette.light,
      typography,
      breakpoints,
      shape: { borderRadius: 8 },
      direction: themeDirection,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              backgroundColor: "green",
              borderRadius: "25px",
              border: "1.5px solid #575756",
              textTransform: "none",
              fontWeight: "700",
              boxShadow: "4px 4px 0px #E5E4E6",
            },
          },
        },
      },
    }),
    [isLight, themeDirection, auth]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
