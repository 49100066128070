import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// hooks
import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '../../config';
// 
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';
import { showFilter, hideFilter } from 'redux/slices/searchContract';
import MobileMenu from './navbar/mobile-menu'
import { AuthSharedContext } from 'contexts/SharedContext';
import UseLargerScreenMessage from 'components/UseLargerScreenMessage';
import ProductTourWelcomeDialog from '../../../src/components/product-tour/ProductTourWelcomeDialog';
import useAuth from 'hooks/useAuth';

// ----------------------------------------------------------------------

type MainStyleProps = {
  collapseClick: boolean;
};

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})<MainStyleProps>(({ collapseClick, theme }) => ({
  flexGrow: 1,
  // paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingTop: "2rem",
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    // paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingTop: "2rem",
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { collapseClick } = useCollapseDrawer();

  const { themeLayout } = useSettings();

  const { user } = useAuth();

  const isDesktop = useResponsive('up', 'md');

  const [openModal, setOpen] = useState(false);

  const verticalLayout = themeLayout === 'vertical';

  const [openTourDialog, setOpenTourDialog] = useState(false);

  const handleNavbarLoaded = () => {
    const productTour = JSON.parse(localStorage.getItem('productTour') || '{}');
    const userEmails = ['pathum+1@codebuilders.se', 'martin.strand@tagd.ai'];
    const userEmail = user?.userInfo?.email || '';

    // Check if the tour is paused or if no productTour key exists
    if (productTour.status !== 'started' && userEmails.includes(userEmail)) {
      setOpenTourDialog(true);
    }
  };

  const onClose = () => {
    console.log("On close")
  }


  if (verticalLayout) {
    return (
      <>
        <AuthSharedContext>
          {/* {isDesktop ? <NavbarHorizontal /> : <MobileMenu />} */}
          <NavbarHorizontal onLoaded={handleNavbarLoaded} />
          <ProductTourWelcomeDialog openTourDialog={openTourDialog} onClose={onClose} />
          <Box
            component="main"
            sx={{
              px: { lg: 2 },
              pt: {
                xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
                lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT}px`,
              },
              pb: {
                xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
                lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT - 30}px`,
              },
            }}
          >
            {isDesktop ? (<Outlet />) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <UseLargerScreenMessage />
              </Box>
            )}
          </Box>
        </AuthSharedContext>
      </>
    );
  }

  return (
    <AuthSharedContext>
      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
          // border:"10px solid red"
        }}
      >
        {/* <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} /> */}

        <NavbarVertical isOpenSidebar={openModal} onCloseSidebar={() => setOpen(false)} />

        <MainStyle collapseClick={collapseClick}>
          <Outlet />
        </MainStyle>
      </Box>
    </AuthSharedContext>
  );
}
