import { Card, Grid, styled, Typography } from '@mui/material'
import { partyContractColumns } from 'constants/initialValues/counterPartiesInitialValues'
import useLocales from 'hooks/useLocales'
import CustomTable from 'layouts/custom-table/custom-table'
import ContractRow from './rows/ContractRow'
import { IContentListResponse } from '../../../@types/contractResponse'
import { CardContent } from '@mui/material'

type Props = {
    contents: IContentListResponse
}

const PartiesContractList = ({ contents }: Props) => {

    const { translate } = useLocales()
    return (
        <Card>
            <CardContent>
                <Grid container >
                    <Grid item sm={12}>
                        <HeaderBetween>
                            <Typography variant='subtitle1'>{translate('parties.counterPartyContractList', 'Counter party contract list')}</Typography>
                        </HeaderBetween>
                    </Grid>
                    <Grid item sm={12}>
                        <CustomTable
                            headers={partyContractColumns(translate)}
                            rowsData={contents?.content?.length ? contents.content : []}
                            SingleRow={ContractRow}
                            allowMultiSelect={false}
                            withoutCard={true}
                            totalRecords={contents?.pagination?.totalRecords}
                            noDataMsg={translate('warnings.noContract', 'No contracts found')}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>


    )
}

export default PartiesContractList


const HeaderBetween = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    marginBottom: "1rem",
    height: "30px",
    ".MuiTypography-root": {
        margin: 0,
        padding: 0,
        color: theme.palette.grey[600]
    }
}))