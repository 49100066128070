import React from 'react';
import { DialogTitle, Dialog, DialogActions, Button, Box, IconButton } from '@mui/material';
import useLocales from 'hooks/useLocales';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';

import Iconify from 'components/Iconify';
type Props = {
  open: boolean;
  onClose: () => void;
  title: string;
  deleteFunction: (id?: number) => void;
  closeOptionModal?: () => void;
  buttonLabel?: {
    text: string;
    value: string;
  };
  deleting?: boolean;
};

export default function Popup(props: Props) {
  const { open, onClose, title, deleteFunction, buttonLabel, closeOptionModal, deleting = false } = props;
  const { translate } = useLocales();

  const closeAll = () => {
    onClose();
    if (closeOptionModal) closeOptionModal();
  };
  return (
    <Dialog onClose={onClose} open={open} maxWidth="sm" fullWidth={true}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Iconify
              icon={'eva:trash-2-outline'}
              sx={{ marginRight: 1, fontSize: '1.5rem', color: 'error.main' }}
            />
            {title}
          </Box>
        </DialogTitle>
        <Box>
          <IconButton onClick={onClose} sx={{ padding: '0.5rem' }}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>

      <DialogActions>
        <LoadingButton variant="contained" onClick={() => deleteFunction()} color="error" loading={deleting}>
          {translate(buttonLabel?.value || 'controls.delete', buttonLabel?.text || 'Delete')}
        </LoadingButton>
        <Button onClick={closeAll}>{translate('controls.cancel', 'Cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
}
