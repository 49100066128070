import { m } from 'framer-motion';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, SxProps, Typography, Button } from '@mui/material';
import ProgressBar from './ProgressBar';
import useAuth from '../hooks/useAuth';
import { COGNITO_API } from '../config';
import useLocales from 'hooks/useLocales';

const RootStyle = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));


type Props = {
  isDashboard?: boolean;
  sx?: SxProps;
};

export default function LoadingScreen({ isDashboard, ...other }: Props) {
  const { displayRetry } = useAuth();
  const { translate } = useLocales();

  const handleRetry = () => {
    window.location.href = COGNITO_API.cognitoUrl;
  }

  return (
    <>
      <ProgressBar />
      {!isDashboard && (
        <RootStyle {...other}>
          {displayRetry ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
              }}
            >
              <Typography variant="h5" sx={{ marginBottom: 2 }}>
                {translate('loadingScreen.theLoginHasHalted', 'The login has halted. Please try again.')}
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleRetry()}
                className="retry-btn"
              >
                {translate('loadingScreen.retry', 'Retry')}
              </Button>
            </Box>
          ) :

            (<>
              <m.div
                initial={{ rotateY: 0 }}
                animate={{ rotateY: 360 }}
                transition={{
                  duration: 2,
                  ease: 'easeInOut',
                  repeatDelay: 1,
                  repeat: Infinity,
                }}
              >
                <Typography variant="h6" sx={{ color: "#212B36" }}>
                  LOADING
                </Typography>
              </m.div>
              <Box
                component={m.div}
                animate={{
                  scale: [1.2, 1, 1, 1.2, 1.2],
                  rotate: [270, 0, 0, 270, 270],
                  opacity: [0.25, 1, 1, 1, 0.25],
                  borderRadius: ['25%', '25%', '50%', '50%', '25%'],
                }}
                transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
                sx={{
                  width: 100,
                  height: 100,
                  borderRadius: '25%',
                  position: 'absolute',
                  border: (theme) => `solid 3px ${alpha("#212B36", 0.24)}`,
                }}
              />
            </>)
          }
        </RootStyle>
      )}
    </>
  );
}
