import React from 'react';
import { TableRow, TableCell, TableHead, TableSortLabel, Checkbox, checkboxClasses, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

type Header = {
  text: string;
  sortable?: boolean;
  field?: string;
};
type Props = {
  headers: Header[];
  allowMultiSelect: boolean;
  direction: 'asc' | 'desc';
  sortHandler: (text: string, direction: 'asc' | 'desc') => void;
  orderBy?: string;
  selectAllHandler: () => void;
};
export default function CustomTableHeaders(props: Props) {
  const theme = useTheme();
  const { headers, allowMultiSelect, direction, sortHandler, orderBy, selectAllHandler } = props;

  let tableHeadCss = {
    backgroundColor: theme.customPalette?.tableHeadColor ?? "",
    backgroundImage: "unset",
    color: theme.customPalette?.tableHeadFontColor ?? "",
    boxShadow: theme.customPalette?.tableHeadBoxShadow ?? "unset !important"
  }

  return (
    <TableHead>
      <TableRow>
        {allowMultiSelect && (
          <TableCell sx={tableHeadCss}>
            <Checkbox
              size="small"
              sx={{
                [`&, &.${checkboxClasses.checked}`]: {
                  color: theme.customPalette?.tableHeadFontColor ?? '',
                },
                padding: 0,
              }} onClick={selectAllHandler} />
          </TableCell>
        )}
        {headers.map((header) => (
          <TableCell
            key={header.text}
            sx={{
              ...tableHeadCss,
              cursor: header.sortable ? 'pointer' : 'default', // Make cell clickable for sortable headers
            }}
            onClick={() =>
              header.sortable && header.field && sortHandler(header.field as string, direction)
            } // Make the whole cell clickable
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              <span>{header.text}</span>
              {header.sortable && header.field && (
                <TableSortLabel
                  active={orderBy === header.field}
                  direction={orderBy === header.field ? direction : 'asc'}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent cell click from triggering sort twice
                    sortHandler(header.field as string, direction);
                  }}
                  sx={{
                    '&.MuiTableSortLabel-root': {
                      color: theme.customPalette?.tableHeadFontColor,
                    },
                    '&.Mui-active': {
                      color: theme.customPalette?.tableHeadFontColor,
                    },
                    '& .MuiTableSortLabel-icon': {
                      color: theme.customPalette?.tableHeadFontColor,
                      fontSize: '1rem',
                      opacity: 0.25,
                      transition: 'opacity 0.2s ease',
                    },
                    '&.Mui-active .MuiTableSortLabel-icon': {
                      color: theme.customPalette?.tableHeadFontColor,
                      opacity: 1,
                    },
                    '&:hover .MuiTableSortLabel-icon': {
                      opacity: 1,
                    },
                  }}
                />
              )}
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
